@import "src/styles/global.module";

.page {
  min-height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.flagBox {
  width: 100%;
  height: 10px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
}
.flagBoxLeft {
  width: 100%;
  height: 10px;

  background-color: $colorBlue;
}
.flagBoxCenter {
  width: 100%;
  height: 10px;

  background-color: $colorMilk;
}
.flagBoxRight {
  width: 100%;
  height: 10px;

  background-color: $colorBlack;
}


.h1 {
  width: calc(100% - 10px);

  font-size: $fontSizeH;
  font-weight: $fontWeightH;

  color: $colorBlue;

  margin: 5px;
  padding: 0;
}
.h2 {
  width: calc(100% - 10px);

  font-size: $fontSizeH;
  font-weight: $fontWeightH;

  color: $colorBlack;

  margin: 5px;
  padding: 0;
}