@import "src/styles/global.module";

.box {
  width: calc(100% - 10px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  margin: 10px 5px;

  border-radius: 15px;

  background: $colorWhite;

  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
}

.boxTitle {
  width: calc(100% - 30px);

  text-transform: uppercase;
  text-align: center;
  font-size: $fontSizeTitle;
  font-weight: $fontWeightTitle;

  color: $colorBlue;
  background: $colorBlue10;

  border-radius: 15px 15px 0 0;

  margin: 0;
  padding: 15px;
}

.boxDescription {
  width: calc(100% - 30px);

  text-align: justify;
  font-size: $fontSizeText;
  font-weight: $fontWeightText;

  margin: 0;
  padding: 15px;
}

.boxButton {
  width: calc(100% - 40px);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  text-decoration: none;

  background: $colorBlack10;

  border-radius: 10px;

  margin: 5px;
  padding: 15px;
}

.boxButtonLeft {
  height: 30px;
}
.boxButtonCenter {
  font-size: $fontSizeLink;
  font-weight: $fontWeightLink;

  color: $colorBlack;

  margin: 0;
  padding: 0;
}
.boxButtonRight {
  height: 30px;
}