@import-normalize;

@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');

html {
  font-family: 'Comfortaa', cursive;
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;

  background: #EEEEEE;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
